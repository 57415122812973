@import "palettes";
@import "theme";
@import "mixins";

.role {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: $gray-500;
  font-family: $sans;

  .role_text {
    font-weight: $medium;
    margin-left: 10px;
  }

  .role_description {
    margin-left: 20px;
  }

  @media (max-width: $phone) {
    .role_description {
      width: 100%;
      margin-left: 0;
      margin-top: 5px;
    }
  }
}
